import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import React, { useContext } from "react";

import Container from "../components/Container";

import Layout from "../components/layout";
import Banner from "../components/Banner";
import PageDescription from "../components/PageDescription";
import SectionGridLayout from "../components/SectionGridLayout";

import useLanguage from "../hooks/useLanguage";
import useUrl from "../hooks/useUrl";

import i18nContext from "../i18n-context";

const PublicationPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const i18n = useContext(i18nContext)[lang];

  const researchCategories = data.wp.homeCategories.filter((category) => {
    if (lang === "tc") return category.language === "zh_TW";
    if (lang === "sc") return category.language === "zh_CN";
    if (lang === "en") return category.language === "en_US";
    return category.language === "en_US";
  });

  const {
    title: pageTitle,
    excerpt: introText,
    translated: pageTranslations,
  } = pageContext;
  const bannerUrl = pageContext.featuredImage?.node?.sourceUrl || "";

  const {
    allWpBooksCategory: { nodes },
    bookPage: {
      uri: bookPageUri,
      locale: { locale: bookPageLocale },
    },
  } = data;

  const bookCategories = nodes.map((node) => {
    return {
      ...node,
      banner_url: "/book_icon.png",
      id: node.id,
      name: node.name,
      uri: (bookPageUri + node.slug).replace("/" + node.slug, "#" + node.slug),
      language: bookPageLocale,
      bottomLineColor: "blue",
    };
  });

  return (
    <Layout pageTranslations={pageTranslations}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${pageTitle}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={pageTitle} />
        <meta
          property="og:description"
          content={introText ? parse(introText)[0]?.props?.children : ""}
        />
        <meta property="og:image" content={bannerUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={pageTitle} />
        <meta
          name="twitter:description"
          content={introText ? parse(introText)[0]?.props?.children : ""}
        />
        <meta name="twitter:image" content={bannerUrl} />
      </Helmet>

      <Banner title={pageTitle} bannerUrl={bannerUrl} />
      <Container>
        {introText && <PageDescription>{parse(introText)}</PageDescription>}
        <SectionGridLayout
          anchorId={i18n.anchor.publications}
          title={i18n.sectionHeadings.publications}
          categories={researchCategories}
        />
        <SectionGridLayout 
          anchorId={i18n.anchor.books}
          title={i18n.sectionHeadings.books}
          categories={bookCategories} 
        />
        <SectionGridLayout
          anchorId={i18n.anchor.speeches}
          title={i18n.sectionHeadings.speeches}
          categories={[
            {
              banner_url: "/speech_icon.png",
              id: data.speechPage.id,
              name: i18n.viewAll,
              uri: data.speechPage.uri,
              language: data.speechPage.locale.locale,
              bottomLineColor: "green",
            },
          ]}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query publicationPageQuery($locale: String) {
    wp {
      homeCategories {
        id
        language
        name
        uri
        banner_url
      }
    }
    allWpBooksCategory(
      filter: { locale: { locale: { eq: $locale } } }
      sort: { fields: termTaxonomyId }
    ) {
      nodes {
        id
        name
        slug
        termTaxonomyId
      }
    }
    bookPage: wpPage(
      pageType: { pageType: { eq: "9" } }
      locale: { locale: { eq: $locale } }
    ) {
      id
      uri
      slug
      name: title
      locale {
        locale
      }
    }
    speechPage: wpPage(
      pageType: { pageType: { eq: "10" } }
      locale: { locale: { eq: $locale } }
    ) {
      id
      uri
      slug
      name: title
      locale {
        locale
      }
    }
  }
`;

export default PublicationPage;
